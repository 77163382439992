import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"info"} />
		<Helmet>
			<title>
				Nasze usługi | Lival Resort
			</title>
			<meta name={"description"} content={"Podróż zmysłowego splendoru i komfortu"} />
			<meta property={"og:title"} content={"Nasze usługi | Lival Resort"} />
			<meta property={"og:description"} content={"Podróż zmysłowego splendoru i komfortu"} />
			<meta property={"og:image"} content={"https://voplaygo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					md-text-align="left"
				>
					Nasze usługi
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Wejdź na brzeg luksusu, gdzie każda usługa w Lival Resort jest świadectwem naszego zaangażowania w najwyższy komfort i przyjemność. Tutaj splendor morza jest płynnie spleciony z finezją naszych ofert, aby zapewnić, że pobyt nie będzie tylko wizytą, ale bogatym gobelinem niezapomnianych chwil.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://voplaygo.com/img/3.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://voplaygo.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Zakwaterowanie dostosowane do spokoju
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Nasze pokoje to sanktuaria spokoju, zaprojektowane z myślą o zapewnieniu spokojnych nocy i radosnych przebudzeń. Każda przestrzeń jest świadectwem przemyślanego luksusu, stworzonego w celu zapewnienia najwyższego komfortu.
					<br />
					Sunrise Sanctuaries: Obudź się w delikatnym świetle słonecznym w tych apartamentach, które celebrują świt.
					<br />
					Tranquility Terrace Rooms: Prywatny taras z widokiem na morze zapewnia idealne miejsce do medytacji lub wieczornych refleksji.
					<br />
					Serenity Family Havens: Przestronność spotyka się tutaj z komfortem, gdzie rodziny znajdują idealne warunki do wspólnego spędzania czasu i wyciszenia.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Kulinarne podróże, które zachwycają zmysły
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Nasza oferta kulinarna to mozaika lokalnych smaków i międzynarodowych technik, obiecująca zabrać podniebienie w podróż tak ekspansywną, jak sam ocean.
					<br />
					Beachfront Bistro: Doświadczenie kulinarne, które łączy połów dnia z urokiem przypływów.
					<br />
					Nadmorskie uczty przy świecach: Gdzie wyśmienita kuchnia spotyka się z piaskiem na niezapomniany wieczór pod gwiazdami.
					<br />
					Sunset Tapas and Cocktails: Idealny wstęp do wieczornego spaceru, ze smakami tak złożonymi i satysfakcjonującymi jak zachód słońca.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://voplaygo.com/img/5.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://voplaygo.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Wydarzenia, które rozbrzmiewają elegancją
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Twoje uroczystości stają się częścią dziedzictwa Lival, a każde wydarzenie jest dostosowane tak, aby odzwierciedlać wielkość morza i intymność Twojej historii.
					Idylliczne śluby: Wymień śluby na tle, które przyćmiewa każdą dekorację.
					Seashore Soirées: Zbierz się nad morzem, gdzie każda okazja jest otoczona nadmorską elegancją.
					<br /><br />
					To tylko namiastka wrażeń z pobytu w Lival Resort. Aby dowiedzieć się więcej o tym, jak może wyglądać Twój pobyt, dostosować podróż lub uzyskać więcej informacji, skontaktuj się z nami.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});